<template>
  <div class="intro">
      <div class="step-title">iOS 升级</div>
      <div class="step-box">
        没有非中国大陆的 Apple ID ？
        <br>
        <br>
        <div class="step-info">
          <ul>
            <li>
              <span class="small-step-number">方法 1</span>
              <a :href="`/blog/ru-he-zhu-ce-ao-da-li-ya-apple-id/?from=${store.env.channel}`">
                注册澳大利亚 Apple ID
              </a>
            </li>
            <li>&nbsp;</li>
            <li>
              <span class="small-step-number">方法 2</span>
              <a :href="`/blog/ping-guo-gong-yong-apple-idshi-yong-jiao-cheng/?from=${store.env.channel}`">
                使用公用 Apple ID
              </a>
            </li>
          </ul>
        </div>

        <hr>
        有非中国大陆的 Apple ID，直接升级 <br> <br>
        <div @click="downloadInstall(['download-button-ios-appstore'], $event)" class="download-button download-button-ios-appstore app-store-like">
        <span>
          <img class="app-icon" src="@/assets/images/icon_app.png"/>
        </span>
          <span>
          <ul>
            <li class="app-title">狗急加速</li>
            <li class="app-intro">将跳转至AppStore</li>
            <li class="app-get">升级</li>
          </ul>
        </span>
        </div>
      </div>
  </div>
</template>

<script>
  import { useStore } from "vuex"
  export default {
    name: 'IOSAppstoreUpgrade',
    components: {},
    setup () {
      let stores = useStore()
      let store = stores.state
      function downloadInstall (className, event) {
        stores.commit('changeToPath', {tag:'toIosAppStoreFaq', value: true})
        let resp = window.install('', className)
        if (!resp) event.preventDefault()
        return resp
      }

      return {
        downloadInstall,
        store
      }
    }
  }
</script>

<style scoped>
.intro .app-store-like .app-get.unpaid {
  background-color: #b2b2b2;
}
.intro .step-box .step-info {
  display: flex;
  justify-content: center;
}
.intro .step-box .step-info ul {
  text-align: left;
}
.intro .small-step-number{
  background-color: black;
  color: white;
  border-radius: 10rem;
  border: none;
  width: auto;
  height: auto;
  line-height: 1.6rem;
  font-size: 1.2rem;
  text-align: center;
  vertical-align: top;
  padding: 1px 8px;
}
.intro .step-box .step-info a{
  margin-left: 4px;
}
</style>
